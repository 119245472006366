.section-home {
  background-color: #f7f7f7;
  padding: 8rem 4rem;
  height: 100%;
}

.home-page-img {
  width: 100%;
  height: 35rem;
}

.section-about {
  background-color: #e6e1e1;
  padding: 8rem 4rem;

  /* height: 100vh; */
}

.section-list-data {
  background-color: #f7f7f7;
  /*  background-color:#e6e1e1; */
  padding: 8rem 0;
}

.app-features {
  background-color: #25adad;
  color: #fff;
  padding: 1rem 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  margin: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 0.4rem 0.4rem;
}

.flex-container {
  display: flex;
}

.box-task {
  margin: 5rem 3rem;
  border: 1px solid #fff;
  background-color: #e6e1e1;
  border-radius: 1rem;
}

.task-headings {
  background-color: #00a6a4;
  background-clip: padding-box;
  padding: 0.5rem 1rem;
  color: #fff;
  font-style: oblique;
  /*font    : 2rem "handwriting", cursive;
  font-weight: 700; */
}

.text-for-no-tasks{
  padding: 2rem 2rem;
 
  text-align: left;
  font-weight: 700;
  
}

.container-cards {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;

  min-height: 0;
  min-width: 0;
  align-items: stretch;
  justify-content: flex-start;
  margin: 4rem 1rem;
  -webkit-overflow-scrolling: auto;
  
}

.container-cards::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
  height: 10px;
}

.container-cards::-webkit-scrollbar-thumb {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.card {
  background-color: #fff;
  margin: 2rem 1rem;

  height: 28rem;
  text-align: left;

  font-size: 1.5rem;
  border-radius: 30px;
  box-shadow: 0 0.4rem 0.4rem;
 /* transition: all 1s ease-out; */
}

.new-card-dimensions{
  height: 24rem !important;
 
}

.card-details {
  padding: 0.5rem;
  font-size: 1.3rem;
}
.new-card-box{
  width: 50%;
  margin-left: 25%;
}
.new-card {
  height: 27rem;
  margin: 0 3rem;
  text-align: left;
  font-size: 1.5rem;
  padding: 2rem 3rem;
}

.card-doc-text {
  padding: 0.5rem;
}

.card-date-input {
  padding: 0.5rem;
  display: block;
}

.card-date-display-mode{
  font-size: 1.6rem;
  font-weight: 700;
}

.card-taskname-display-mode{
  font-size: 1.7rem;
  font-weight: 700;
}

.card-notes {
  padding: 0.5rem;
  overflow-y: scroll;
  display: block;
  width: 100%;
  resize: none;
  -webkit-overflow-scrolling: auto;
}

.card-notes::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 5px;
}

.card-notes::-webkit-scrollbar-thumb {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  background-color: #5e5e5e;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.new-card-img {
  width: 100%;
  height: 27rem;
}

.edit-mode {
  border: 1px solid #5e5e5e;
  /* box-shadow: 5px 10px #888888; */
  background-color: #dce7ec;
}
.display-mode {
  border: 0;
}




/* .show-card{
//     opacity: 1;
// }

// .fadeOut-card{
//     opacity: 0
// }
// .deleted-fade-in {
//   animation: fadeOut ease 1.3s;
//   -webkit-animation: fadeOut ease 1s;
// }

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }

// @-moz-keyframes fadeOut {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes fadeOut {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-o-keyframes fadeOut {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-ms-keyframes fadeOut {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// } */

/*
// .notes {
//   padding: 0.5rem;
//   overflow-y: scroll;
//   display: block;
//   width: 100%;

//   resize: none;
// }

// .notes::-webkit-scrollbar {
//   width: 4px;
//   height: 5px;
// }

// .notes::-webkit-scrollbar-thumb {
//   border: 1px solid #5e5e5e;
//   border-radius: 4px;
//   background-color: #5e5e5e;
//   box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }


*/
