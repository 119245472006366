/* transitions */
.move-enter {
    opacity: 0;
  
}

.move-enter-active {
    opacity: 1;
    transition: all 500ms ease-out;
}

.move-exit {
    opacity: 1;
    
}

.move-exit-active {
    opacity: 0;
    transition: all 500ms ease-out;
}

.move-appear {
    opacity: 0;
  
}

.move-appear-active {
    opacity: 1;
    transition: all 500ms ease-out;
}
