.btn {
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
background-color: #FF9331;
}

.btn-header {
  
  color: #fff;
 /* text-transform: uppercase; */
  padding: 0.8rem 1rem;
  border-radius: 1.5rem;
  font-size: 1.8rem;
}


.btn-medium {
  
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  font-size: 1.3rem;
}

.btn:hover {
  transform: scaleX(1.2) scaleY(1.1);
}



.btn-medium-ghost {
  background-color: transparent;
 
  padding: 0.4rem 0.5rem;
  border-radius: 0.8rem;
  font-size: 1.1rem;
  border: 2px solid #FF9331;

  margin-top: 0.5rem;
  margin-left: 1.5rem;
  color: #fff;
}

.btn-nav-bar{
  margin-top: 1rem;
}

.btn-icon {
  border: 1px solid #FF9331;

 
  border-radius: 4px;
  
  box-shadow: 2px 2px #888888; 
  transition: all 0.2s;
}

.btn-icon:hover {
  transform: scaleX(1.4) scaleY(1.2);
}

.btn-disabled{
  background-color: grey !important;
  cursor: none !important;
  
}

.icon-edit {
  text-align: right;
  padding-top: 1rem;
  padding-right: 2rem;
  font-size: 1.25rem;
}

.icon-delete {
  text-align: right;
  padding-right: 2rem;
  font-size: 1.25rem;
}

.icon-close {
  text-align: right;
  padding-right: 2rem;
  font-size: 1.25rem;
}

.icon-toggle{
  font-size: 1.5rem;
  padding: .5rem;
  margin-right: 2rem;
}




