*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* This defines what 1rem is*/
  /*1 rem = 12px;  */
  font-size: 12px;
  text-rendering: optimizeLegibility;
}

body {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  /*font-size: 10px; */
  font-weight: 400, 700;
  line-height: 1.7;
  color: rgb(57,58,61);
}
