/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop */

/*1800px+  big desktops  //1rem = 16px*/
@media only screen and (min-width: 1800px) {
  html {
    font-size: 16px;
  }
}

/*1200px Tablet landscape //1 rem = 12px */
@media only screen and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
  .row {
    padding: 0 2%;
  }
 
  .app-features{
   margin: 0.5rem; 
  }


}

/*900px Tablet portrait //1 rem = 10px */
@media only screen and (max-width: 900px) {
  html {
    font-size: 10px;
  }
  .new-card-img {
    margin-top: 5rem;
    height: 15rem;
    text-align:right;
  }
  .new-card-dimensions{
    height: 15rem !important;
    
  }

}

/*600px Phone //1 rem = 9px*/
@media only screen and (max-width: 600px) {
  html {
    font-size: 9px;
  }
 
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 8px;
  }
} 








