
/* header {
//   height: 100vh;
//   width: 100%;
//   background-image: 
//     url(../images/header-img.svg);
//   background-size: cover;
//   background-position: center;
//   background-attachment: fixed;

//   position: relative;

}*/

.nav-bar{
  background-color: #00a6a4;
  width: 100%;
  height: 5rem;
}

.header__logo-box {
  position: absolute;
  top: 1rem;
  left: 3rem;
}

.header__logo {
  height: 3.5rem;
}

.header__userinfo {
  position: absolute;
  right: 3rem;
  height: 5rem;
  text-align: right;
  color: #fff;
  
  
}

.header__text-box {
  position: relative;
  top: 30%;
  left: 10%;
  text-align: left;
  
}
