.heading-primary {
  margin-bottom: 3rem;
}

.heading-primary--main {
  display: block;
  font-size: 5rem;
  font-weight: 700;
  letter-spacing: 1rem;
  text-transform: uppercase;
  font: 5rem "handwriting", cursive;
}

.heading-primary--sub {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  
}

.heading-secondary {
  font-size: 2.8rem;

  font-weight: 400;
  display: inline-block;
  letter-spacing: 0.2rem;
 
}

.heading-tertiary {
  font-size: 1.8rem;
  font-weight: 400;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.5rem 1rem;
}

.paragraph {
  font-size: 1.6rem;
}

label{
  font-style: oblique;
}

input, textarea{

  font-size: 1.6rem;
  border: 1px solid #fff;
  border-radius: 0.6rem;
  

}

::placeholder {
  font-style: italic;
  
}

*:focus {
  outline: none;
}

/*
// .list {
//   font-size: 1.9rem;
// }

// label {
//   margin-right: 1rem;
//   font-size: 1.9rem;
// }

// input,
// textarea {
//   font-size: 1.9rem;
// }

// .italics-text {
//   font-size: 1.7rem;
//   font-weight: 400;
// }

// *:focus {
//   outline: none;
// }
*/